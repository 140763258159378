import React from 'react'

const strokeStyle = {
	fill: 'none',
	vectorEffect: 'non-scaling-stroke',
	stroke: 'black',
	strokeWidth: 1
}

const InfoIcon = ({width, height}) => (
	<svg
		viewBox='0 0 40 62'
		width={width}
		height={height}
		xmlns='http://www.w3.org/2000/svg'
		preserveAspectRatio='xMidYMid'
	>
		<line style={strokeStyle} x1="17.1" y1="55.4" x2="17.1" y2="61"/>
		<path style={strokeStyle} d="M4.8,16.2C4.8,7.9,11.6,1,20,1s15.2,6.8,15.2,15.2S28.4,31.4,20,31.4h-2.9V49"/>
	</svg>
)

export default InfoIcon
