import React, {useState} from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './panorama.module.css'

const Panorama = ({panoramaImg, bgColor}) => {
	const [panPosition, setPanPosition] = useState('0')
	const [isTouch, setIsTouch] = useState(false)

	// TODO:
	//	- animate the pan when mouse enters from a different position, 
	//    rather than a quick snap - more elegant?

	const onMouseMove = (e) => {
		// if (!isTouch) {
			const pos = e.pageX / window.innerWidth
			const panWidth = document.getElementById('panorama').width - window.innerWidth
			// const panWidth = document.querySelector('[class^=panorama-module]').getElementsByTagName("IMG").scrollWidth - window.innerWidth
			// console.log('pos', pos)
			// console.log('panWidth', panWidth)
			// console.log('pos * panWidth', pos * panWidth)
			setPanPosition(`-${pos * panWidth}px`)
		// }
	}

	const onTouchStart = (e) => {
		if (!isTouch) {
			setPanPosition('0')
			setIsTouch(true)
		}
	}

	return (
		<div
			className={`${styles.root} ${isTouch ? styles.touch : ''}`}
			style={{
				backgroundColor: `${bgColor}`
			}}
			onMouseMove={onMouseMove}
			onTouchStart={onTouchStart}
		>
			{panoramaImg && <img
				id="panorama"
				src={imageUrlFor(buildImageObj(panoramaImg))
					.height(1800)
					.url()}
				alt={panoramaImg.alt}
				style={{
					transform: `translateX(${panPosition})`
				}}
			/>}
		</div>
	)
}

export default Panorama
