import {Link} from 'gatsby'
import React from 'react'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PlusIcon from './icon/plus'
import InfoIcon from './icon/info'

import styles from './header.module.css'

const Header = ({siteTitle, logo}) => (
	<header className={styles.root}>
		<nav className={styles.nav}>
			<ul>
				<li>
					<Link to='/work/' className={styles.link}>Work</Link>
					<Link to='/work/' className={styles.mobileLink}>
						<PlusIcon width="24" height="24" />
					</Link>
				</li>
			</ul>
		</nav>

		<div className={styles.logo}>
			<Link to='/'>
				<img
					src={imageUrlFor(buildImageObj(logo))
						.width(600)
						.url()}
					alt={logo.alt}
				/>
			</Link>
		</div>

		<nav className={styles.nav}>
			<ul>
				<li>
					<Link to='/info/' className={styles.link}>Info</Link>
					<Link to='/info/' className={styles.mobileLink}><InfoIcon width="16.75" height="26" /></Link>
				</li>
			</ul>
		</nav>
	</header>
)

export default Header
