import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './gallery.module.css'

const Gallery = ({gallery}) => {
	return (
		<div className={styles.root}>
			{gallery && gallery.length > 0 &&
				<CarouselProvider
					className={styles.carousel}
					// naturalSlideWidth={100}
					// naturalSlideHeight={100}
					naturalSlideWidth={100}
					naturalSlideHeight={50}
					totalSlides={gallery.length}
					infinite={true}
				>
					<Slider className={styles.slider}>
					{gallery.map((item, index) => (
						<Slide
							className={styles.slide}
							index={index}
							key={index}
						>
							<figure className={styles.figure}>
								<img
									src={imageUrlFor(buildImageObj(item))
										.height(1800)
										.url()}
									alt={item.alt}
								/>
								<figcaption className={styles.figcaption}>
									<p>{index + 1}/{gallery.length}&emsp;{item.caption}</p>
								</figcaption>
							</figure>
						</Slide>
					))}
					</Slider>
					<ButtonBack className={`${styles.carouselButton} ${styles.carouselButtonPrevious}`} />
					<ButtonNext className={`${styles.carouselButton} ${styles.carouselButtonNext}`} />
				</CarouselProvider>
			}
		</div>
	)
}

export default Gallery
