import React, { useState, useEffect } from 'react'
import {formatToTimeZone} from 'date-fns-timezone'

const ClockIcon = (props) => {
	const [time, setTime] = useState(
		formatToTimeZone(new Date(), 'hh:mm', {timeZone: props.timezone})
	)

	useEffect(() => {
		let interval = setInterval(() => {
			setTime(formatToTimeZone(new Date(), 'hh:mm', {timeZone: props.timezone}))
		}, 1000)
		return () => clearInterval(interval);
	}, [time])

	const diameter = 60

	const center = {
		x: diameter/2,
		y: diameter/2,
	}

	const lengths = {
		hour: diameter * 0.25,
		minutes: diameter * 0.35,
	}

	const floatingHour = (time.split(':')[0] % 12) + (time.split(':')[1] / 60)

	const angle = {
		hour: 2.0 * Math.PI * floatingHour / 12.0,
		minute: 2.0 * Math.PI * floatingHour,
	}

	return (
		<svg
			viewBox={`0 0 ${diameter} ${diameter}`}
			// width={diameter}
			// height={diameter}
			style={{
				border: '1px solid black',
				borderRadius: '50%'
			}}
		>
			<g id="markers">
				<line
					id="noon"
					style={{ stroke: 'black', strokeWidth: 1, vectorEffect: 'non-scaling-stroke' }}
					x1={center.x}
					y1="4"
					x2={center.x}
					y2="6"
				/>
				<line
					id="three"
					style={{ stroke: 'black', strokeWidth: 1, vectorEffect: 'non-scaling-stroke' }}
					x1={diameter - 4}
					y1={center.y}
					x2={diameter - 6}
					y2={center.y}
				/>
				<line
					id="six"
					style={{ stroke: 'black', strokeWidth: 1, vectorEffect: 'non-scaling-stroke' }}
					x1={center.x}
					y1={diameter - 4}
					x2={center.x}
					y2={diameter - 6}
				/>
				<line
					id="nine"
					style={{ stroke: 'black', strokeWidth: 1, vectorEffect: 'non-scaling-stroke' }}
					x1="4"
					y1={center.y}
					x2="6"
					y2={center.y}
				/>
			</g>
			<g id="hands">
				<line
					style={{ stroke: 'black', strokeWidth: 1, vectorEffect: 'non-scaling-stroke' }}
					x1={center.x}
					y1={center.y}
					x2={center.x + lengths.hour * Math.sin(angle.hour)}
					y2={center.y - lengths.hour * Math.cos(angle.hour)}
				/>
				<line
					style={{ stroke: 'black', strokeWidth: 1, vectorEffect: 'non-scaling-stroke' }}
					x1={center.x}
					y1={center.y}
					x2={center.x + lengths.minutes * Math.sin(angle.minute)}
					y2={center.y - lengths.minutes * Math.cos(angle.minute)}
				/>
			</g>
		</svg>
	)
}

// ClockIcon.propTypes = {
//   hours: React.PropTypes.number,
//   minutes: React.PropTypes.number,
// }

ClockIcon.defaultProps = {
	hours: 12,
	minutes: 15,
}

export default ClockIcon
