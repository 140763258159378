import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
	query ProjectTemplateQuery($id: String!) {
		project: sanityProject(id: {eq: $id}) {
			id
			title
			slug {
				current
			}
			publishedAt
			category {
				_id
				catColor {
					color {
						hex
					}
				}
				catColor {
					color {
						hex
					}
				}
			}
			shape {
				key
			}
			panorama {
				asset {
					_id
				}
				alt
			}
			primaryImage {
				asset {
					_id
				}
				alt
			}
			locationName
			coordinates {
				lat
				lng
			}
			timezone
			designDate(formatString: "YYYY")
			completionDate(formatString: "YYYY")
			size
			misc
			_rawBody
			gallery {
				asset {
					_id
				}
				alt
				caption
			}
			relatedProjects {
				title
				id
				slug {
					current
				}
				primaryImage {
					asset {
						_id
					}
				}
				category {
					_id
					catColor {
						color {
							hex
						}
					}
					catColor {
						color {
							hex
						}
					}
				}
				shape {
					key
				}
			}
		}
	}
`

const ProjectTemplate = (props) => {
  const {data, errors} = props
  const project = data && data.project
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {project && <SEO title={project.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project {...project} />}
    </Layout>
  )
}

export default ProjectTemplate
