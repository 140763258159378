import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, siteTitle, logo}) => (
	<>
	    <Header siteTitle={siteTitle} logo={logo} />
	    <main className={styles.content}>{children}</main>
	    <footer className={styles.footer}>
	        <p className={styles.siteInfo}>Copyright © {new Date().getFullYear()} Chibbernoonie</p>
	    </footer>
	</>
)

export default Layout
