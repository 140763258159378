import React, { useLayoutEffect, useState } from 'react'
import Clock from 'react-live-clock'
import ClockIcon from './icon/clock.js'
import WeatherIconDefs from './icon/weather-icons'

import styles from './project-meta.module.css'

const ProjectMeta = (props) => {
	const {
		title,
		coordinates,
		timezone,
		galleryExists,
		galleryIsVisible,
		toggleGallery
	} = props

	const [weather, setWeather] = useState(false)

	useLayoutEffect(() => {
		if (coordinates) {
			fetch(`/.netlify/functions/weatherHandler?lat=${coordinates.lat}&lon=${coordinates.lng}`)
			.then(res => res.json())
			.then(res => {
				setWeather(res)
				console.log("res", res)
			})
		}
	}, [coordinates])

	return (
		<div className={styles.root}>
			<ul className={styles.metaList}>
				{title && (
					<li>
						{title}
					</li>
				)}
				{coordinates && (
					<li>
						{coordinates.lat}, {coordinates.lng}
					</li>
				)}
				{timezone && (
					<li className={styles.time}>
						<ClockIcon
							timezone={timezone}
						/>
						<Clock
							format={'h:mm A'}
							ticking={true}
							timezone={timezone}
						/>
					</li>)}
				{weather && (
					<li className={styles.weather}>
						<WeatherIconDefs />
						{weather.weather && (
							<svg className={styles.weatherIcon}>
								<title>{`${weather.weather[0].description} icon`}</title>
								<use xlinkHref={`#${weather.weather[0].icon}`} />
							</svg>
						)}
						{weather.main && (
							<p>{Math.round(weather.main.temp)}˚ F</p>
						)}
					</li>
				)}
				{galleryExists && (
					<li>
						<button className={`${styles.galleryButton} ${galleryIsVisible ? styles.galleryButtonOpen : ''}`} onClick={toggleGallery}>
							{galleryIsVisible ? 'Back to Panorama' : 'View Gallery'}
						</button>
					</li>
				)}
			</ul>
		</div>
	)
}

export default ProjectMeta

// NOTES:
// http://api.timezonedb.com/v2.1/get-time-zone
// https://www.npmjs.com/package/react-sticky-fill
