import {format, distanceInWords, differenceInDays} from 'date-fns'
import React, {useState} from 'react'
import {Link} from 'gatsby'
import {animateScroll as scroll} from 'react-scroll'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Panorama from './panorama'
import Gallery from './gallery'
import ProjectMeta from './project-meta'
import BlockContent from './block-content'
import RelatedProjects from './related-projects'
import MaskDefs from './shapes/masks'
import ShapeOutline from './shapes/outlines'

import styles from './project.module.css'

const Project = (props) => {
	const {
		publishedAt,
		title,
		category,
		panorama,
		primaryImage,
		locationName,
		coordinates,
		timezone,
		designDate,
		completionDate,
		size,
		misc,
		_rawBody,
		gallery,
		relatedProjects,
		shape
	} = props

	const [galleryIsVisible, setGalleryIsVisible] = useState(false)

	const toggleGallery = () => {
		scroll.scrollToTop()
		setGalleryIsVisible(!galleryIsVisible)
	}

	return (
		<>
		<MaskDefs />
		{panorama && !galleryIsVisible && (
			<Panorama panoramaImg={panorama} bgColor={category && category.catColor.color.hex} />
		)}
		{gallery && gallery.length > 0 && galleryIsVisible && (
			<Gallery gallery={gallery} />
		)}
		<ProjectMeta
			title={title}
			coordinates={coordinates}
			timezone={timezone}
			galleryExists={gallery && gallery.length > 0}
			galleryIsVisible={galleryIsVisible}
			toggleGallery={toggleGallery}
		/>

		<article className={styles.article}>
			{props.primaryImage && primaryImage.asset && (
				<div className={styles.primaryImage}>
					<img
						src={imageUrlFor(buildImageObj(primaryImage))
							.width(1200)
							// .fit('crop')
							.url()}
						alt={primaryImage.alt}
						style={{clipPath: `url(#shape--${shape.key})`, WebkitClipPath: `url(#shape--${shape.key})`}}
					/>
					<ShapeOutline
						className={styles.shapeOutline}
						shape={shape.key}
						strokeColor={category.catColor.color.hex}
					/>
				</div>
			)}
			<h1 className={styles.title}>{title}</h1>
			<aside className={styles.metaContent}>
				<ul>
					{designDate && completionDate && (
						<li>Design/Completion: {designDate}/{completionDate}</li>
					)}
					{locationName && (
						<li>{locationName}</li>
					)}
					{size && (
						<li>{Intl.NumberFormat().format(size)} square feet</li>
					)}
					{misc && misc.map( (item) => (
						<li key={item}>{item}</li>
					))}
				</ul>
			</aside>
			<div className={styles.articleBody}>
				{_rawBody && <BlockContent blocks={_rawBody || []} />}
			</div>
		</article>

		{relatedProjects && relatedProjects.length > 0 && (
			<RelatedProjects relatedProjects={relatedProjects} />
		)}
		</>
	)
}

export default Project
