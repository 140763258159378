import React from 'react'

const style = {
	position: 'absolute',
	width: '0',
	height: '0'
}

const strokeStyle = {
	fill: 'none',
	stroke: '#000000',
	strokeMiterlimit: '10',
	strokeLinecap: 'round',
	vectorEffect: 'non-scaling-stroke'
}

const filledStrokeStyle = {
	...strokeStyle,
	fill: '#FFFFFF'
}

const filledStyle = {
	...strokeStyle,
	fill: '#000000'
}

const WeatherIconDefs = () => (
	<svg xmlns='http://www.w3.org/2000/svg' style={style}>
		<symbol id="weatherIcon" viewBox='0 0 64 64'>
			<circle style={strokeStyle} cx="44.8" cy="17.5" r="12.5"/>
		</symbol>
		<symbol id="01d" viewBox='0 0 64 64'>
			<circle style={strokeStyle} cx="32" cy="32" r="12.5"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="32" y2="8.2"/>
			<line style={strokeStyle} x1="32" y1="55.8" x2="32" y2="48.3"/>
			<line style={strokeStyle} x1="15.7" y1="32" x2="8.2" y2="32"/>
			<line style={strokeStyle} x1="55.8" y1="32" x2="48.3" y2="32"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="48.8" y2="15.2"/>
			<line style={strokeStyle} x1="15.2" y1="48.8" x2="20.5" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="15.2" y2="15.2"/>
			<line style={strokeStyle} x1="48.8" y1="48.8" x2="43.5" y2="43.5"/>
		</symbol>
		<symbol id="01n" viewBox='0 0 64 64'>
			<circle style={filledStyle} cx="32" cy="32" r="12.5"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="32" y2="8.2"/>
			<line style={strokeStyle} x1="32" y1="55.8" x2="32" y2="48.3"/>
			<line style={strokeStyle} x1="15.7" y1="32" x2="8.2" y2="32"/>
			<line style={strokeStyle} x1="55.8" y1="32" x2="48.3" y2="32"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="48.8" y2="15.2"/>
			<line style={strokeStyle} x1="15.2" y1="48.8" x2="20.5" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="15.2" y2="15.2"/>
			<line style={strokeStyle} x1="48.8" y1="48.8" x2="43.5" y2="43.5"/>
		</symbol>
		<symbol id="02d" viewBox='0 0 64 64'>
			<circle style={strokeStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
		</symbol>
		<symbol id="02n" viewBox='0 0 64 64'>
			<circle style={filledStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
		</symbol>
		<symbol id="03d" viewBox='0 0 64 64'>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
		</symbol>
		<symbol id="03n" viewBox='0 0 64 64'>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
		</symbol>
		<symbol id="04d" viewBox='0 0 64 64'>
			<path style={filledStrokeStyle} d="M56.9,13.8h-0.2v-0.1c0.1-3.2-2.5-5.9-5.7-5.9c0,0,0,0,0,0c-1.1,0-2.1,0.3-3,0.9c-0.4-3.8-3.8-6.5-7.5-6.1
				c-3.4,0.4-6.1,3.3-6.1,6.7c0,0.7,0.1,1.5,0.4,2.1c-0.5-0.1-1-0.2-1.5-0.2c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8h23.7
				c3.1,0,5.6-2.5,5.6-5.6C62.4,16.3,59.9,13.8,56.9,13.8z"/>
			<path style={filledStrokeStyle} d="M44.3,27.3H44c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C51.5,30.6,48.3,27.5,44.3,27.3L44.3,27.3z"/>
		</symbol>
		<symbol id="04n" viewBox='0 0 64 64'>
			<path style={filledStyle} d="M56.9,13.8h-0.2v-0.1c0.1-3.2-2.5-5.9-5.7-5.9c0,0,0,0,0,0c-1.1,0-2.1,0.3-3,0.9c-0.4-3.8-3.8-6.5-7.5-6.1
				c-3.4,0.4-6.1,3.3-6.1,6.7c0,0.7,0.1,1.5,0.4,2.1c-0.5-0.1-1-0.2-1.5-0.2c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8h23.7
				c3.1,0,5.6-2.5,5.6-5.6C62.4,16.3,59.9,13.8,56.9,13.8z"/>
			<path style={filledStrokeStyle} d="M44.3,27.3H44c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C51.5,30.6,48.3,27.5,44.3,27.3L44.3,27.3z"/>
		</symbol>
		<symbol id="09d" viewBox='0 0 64 64'>
			<path style={filledStrokeStyle} d="M56.9,13.8h-0.2v-0.1c0.1-3.2-2.5-5.9-5.7-5.9c0,0,0,0,0,0c-1.1,0-2.1,0.3-3,0.9c-0.4-3.8-3.8-6.5-7.5-6.1
				c-3.4,0.4-6.1,3.3-6.1,6.7c0,0.7,0.1,1.5,0.4,2.1c-0.5-0.1-1-0.2-1.5-0.2c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8h23.7
				c3.1,0,5.6-2.5,5.6-5.6C62.4,16.3,59.9,13.8,56.9,13.8z"/>
			<path style={filledStrokeStyle} d="M44.3,27.3H44c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C51.5,30.6,48.3,27.5,44.3,27.3L44.3,27.3z"/>
			<line style={strokeStyle} x1="20" y1="47.2" x2="20" y2="49.6"/>
			<line style={strokeStyle} x1="20" y1="53.7" x2="20" y2="56.1"/>
			<line style={strokeStyle} x1="20" y1="59.7" x2="20" y2="62"/>
			<line style={strokeStyle} x1="12.7" y1="47.2" x2="12.7" y2="49.6"/>
			<line style={strokeStyle} x1="12.7" y1="53.7" x2="12.7" y2="56.1"/>
			<line style={strokeStyle} x1="12.7" y1="59.7" x2="12.7" y2="62"/>
			<line style={strokeStyle} x1="27.2" y1="47.6" x2="27.2" y2="50"/>
			<line style={strokeStyle} x1="27.2" y1="54.1" x2="27.2" y2="56.5"/>
			<line style={strokeStyle} x1="27.2" y1="60" x2="27.2" y2="62.4"/>
			<line style={strokeStyle} x1="34.5" y1="47.2" x2="34.5" y2="49.6"/>
			<line style={strokeStyle} x1="34.5" y1="53.7" x2="34.5" y2="56.1"/>
			<line style={strokeStyle} x1="34.5" y1="59.7" x2="34.5" y2="62"/>
			<line style={strokeStyle} x1="41.8" y1="47.2" x2="41.8" y2="49.6"/>
			<line style={strokeStyle} x1="41.8" y1="53.7" x2="41.8" y2="56.1"/>
			<line style={strokeStyle} x1="41.8" y1="59.7" x2="41.8" y2="62"/>
		</symbol>
		<symbol id="09n" viewBox='0 0 64 64'>
			<path style={filledStyle} d="M56.9,13.8h-0.2v-0.1c0.1-3.2-2.5-5.9-5.7-5.9l0,0c-1.1,0-2.1,0.3-3,0.9c-0.4-3.8-3.8-6.5-7.5-6.1
				c-3.4,0.4-6.1,3.3-6.1,6.7c0,0.7,0.1,1.5,0.4,2.1c-0.5-0.1-1-0.2-1.5-0.2c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8H57
				c3.1,0,5.6-2.5,5.6-5.6C62.4,16.3,59.9,13.8,56.9,13.8z"/>
			<path style={filledStrokeStyle} d="M44.3,27.3H44c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C51.5,30.6,48.3,27.5,44.3,27.3L44.3,27.3z"/>
			<line style={strokeStyle} x1="20" y1="47.2" x2="20" y2="49.6"/>
			<line style={strokeStyle} x1="20" y1="53.7" x2="20" y2="56.1"/>
			<line style={strokeStyle} x1="20" y1="59.7" x2="20" y2="62"/>
			<line style={strokeStyle} x1="12.7" y1="47.2" x2="12.7" y2="49.6"/>
			<line style={strokeStyle} x1="12.7" y1="53.7" x2="12.7" y2="56.1"/>
			<line style={strokeStyle} x1="12.7" y1="59.7" x2="12.7" y2="62"/>
			<line style={strokeStyle} x1="27.2" y1="47.6" x2="27.2" y2="50"/>
			<line style={strokeStyle} x1="27.2" y1="54.1" x2="27.2" y2="56.5"/>
			<line style={strokeStyle} x1="27.2" y1="60" x2="27.2" y2="62.4"/>
			<line style={strokeStyle} x1="34.5" y1="47.2" x2="34.5" y2="49.6"/>
			<line style={strokeStyle} x1="34.5" y1="53.7" x2="34.5" y2="56.1"/>
			<line style={strokeStyle} x1="34.5" y1="59.7" x2="34.5" y2="62"/>
			<line style={strokeStyle} x1="41.8" y1="47.2" x2="41.8" y2="49.6"/>
			<line style={strokeStyle} x1="41.8" y1="53.7" x2="41.8" y2="56.1"/>
			<line style={strokeStyle} x1="41.8" y1="59.7" x2="41.8" y2="62"/>
		</symbol>
		<symbol id="10d" viewBox='0 0 64 64'>
			<circle style={strokeStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
			<line style={strokeStyle} x1="25" y1="47.2" x2="25" y2="49.6"/>
			<line style={strokeStyle} x1="25" y1="53.7" x2="25" y2="56.1"/>
			<line style={strokeStyle} x1="25" y1="59.7" x2="25" y2="62"/>
			<line style={strokeStyle} x1="17.7" y1="47.2" x2="17.7" y2="49.6"/>
			<line style={strokeStyle} x1="17.7" y1="53.7" x2="17.7" y2="56.1"/>
			<line style={strokeStyle} x1="17.7" y1="59.7" x2="17.7" y2="62"/>
			<line style={strokeStyle} x1="32.2" y1="47.6" x2="32.2" y2="50"/>
			<line style={strokeStyle} x1="32.2" y1="54.1" x2="32.2" y2="56.5"/>
			<line style={strokeStyle} x1="32.2" y1="60" x2="32.2" y2="62.4"/>
			<line style={strokeStyle} x1="39.5" y1="47.2" x2="39.5" y2="49.6"/>
			<line style={strokeStyle} x1="39.5" y1="53.7" x2="39.5" y2="56.1"/>
			<line style={strokeStyle} x1="39.5" y1="59.7" x2="39.5" y2="62"/>
			<line style={strokeStyle} x1="46.8" y1="47.2" x2="46.8" y2="49.6"/>
			<line style={strokeStyle} x1="46.8" y1="53.7" x2="46.8" y2="56.1"/>
			<line style={strokeStyle} x1="46.8" y1="59.7" x2="46.8" y2="62"/>
		</symbol>
		<symbol id="10n" viewBox='0 0 64 64'>
			<circle style={filledStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
			<line style={strokeStyle} x1="25" y1="47.2" x2="25" y2="49.6"/>
			<line style={strokeStyle} x1="25" y1="53.7" x2="25" y2="56.1"/>
			<line style={strokeStyle} x1="25" y1="59.7" x2="25" y2="62"/>
			<line style={strokeStyle} x1="17.7" y1="47.2" x2="17.7" y2="49.6"/>
			<line style={strokeStyle} x1="17.7" y1="53.7" x2="17.7" y2="56.1"/>
			<line style={strokeStyle} x1="17.7" y1="59.7" x2="17.7" y2="62"/>
			<line style={strokeStyle} x1="32.2" y1="47.6" x2="32.2" y2="50"/>
			<line style={strokeStyle} x1="32.2" y1="54.1" x2="32.2" y2="56.5"/>
			<line style={strokeStyle} x1="32.2" y1="60" x2="32.2" y2="62.4"/>
			<line style={strokeStyle} x1="39.5" y1="47.2" x2="39.5" y2="49.6"/>
			<line style={strokeStyle} x1="39.5" y1="53.7" x2="39.5" y2="56.1"/>
			<line style={strokeStyle} x1="39.5" y1="59.7" x2="39.5" y2="62"/>
			<line style={strokeStyle} x1="46.8" y1="47.2" x2="46.8" y2="49.6"/>
			<line style={strokeStyle} x1="46.8" y1="53.7" x2="46.8" y2="56.1"/>
			<line style={strokeStyle} x1="46.8" y1="59.7" x2="46.8" y2="62"/>
		</symbol>
		<symbol id="11d" viewBox='0 0 64 64'>
			<circle style={strokeStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
			<polyline style={filledStrokeStyle} points="17.9,46.6 21.6,54.8 16.8,54.8 21,62.4 "/>
			<polyline style={filledStrokeStyle} points="30.7,46.6 34.4,54.8 29.6,54.8 33.8,62.4 "/>
			<polyline style={filledStrokeStyle} points="43,46.6 46.8,54.8 41.9,54.8 46.2,62.4 "/>
		</symbol>
		<symbol id="11n" viewBox='0 0 64 64'>
			<circle style={filledStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
			<polyline style={filledStrokeStyle} points="17.9,46.6 21.6,54.8 16.8,54.8 21,62.4 "/>
			<polyline style={filledStrokeStyle} points="30.7,46.6 34.4,54.8 29.6,54.8 33.8,62.4 "/>
			<polyline style={filledStrokeStyle} points="43,46.6 46.8,54.8 41.9,54.8 46.2,62.4 "/>
		</symbol>
		<symbol id="13d" viewBox='0 0 64 64'>
			<line style={strokeStyle} x1="32" y1="48.3" x2="32" y2="8.2"/>
			<line style={strokeStyle} x1="32" y1="55.8" x2="32" y2="48.3"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="8.2" y2="32"/>
			<line style={strokeStyle} x1="55.8" y1="32" x2="48.3" y2="32"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="48.8" y2="15.2"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="37.3" y2="10.4"/>
			<line style={strokeStyle} x1="15.2" y1="48.8" x2="20.5" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="15.2" y2="15.2"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="26.7" y2="10.4"/>
			<line style={strokeStyle} x1="32" y1="48.3" x2="26.7" y2="53.6"/>
			<line style={strokeStyle} x1="32" y1="48.3" x2="37.3" y2="53.6"/>
			<line style={strokeStyle} x1="8.2" y1="32" x2="15.7" y2="32"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="53.6" y2="37.3"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="53.6" y2="26.7"/>
			<line style={strokeStyle} x1="15.7" y1="32" x2="10.4" y2="26.7"/>
			<line style={strokeStyle} x1="15.7" y1="32" x2="10.4" y2="37.3"/>
			<line style={strokeStyle} x1="48.8" y1="48.8" x2="43.5" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="43.6" x2="48.8" y2="15.2"/>
			<line style={strokeStyle} x1="15.2" y1="48.8" x2="20.5" y2="43.6"/>
			<line style={strokeStyle} x1="43.5" y1="43.6" x2="15.2" y2="15.2"/>
			<line style={strokeStyle} x1="48.8" y1="48.8" x2="43.5" y2="43.6"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="55.8" y2="32"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="51" y2="20.5"/>
			<line style={strokeStyle} x1="8.2" y1="32" x2="15.7" y2="32"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="32" y2="8.3"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="43.5" y2="13"/>
			<line style={strokeStyle} x1="20.5" y1="43.6" x2="13" y2="43.6"/>
			<line style={strokeStyle} x1="20.5" y1="43.6" x2="20.5" y2="51"/>
			<line style={strokeStyle} x1="15.2" y1="15.2" x2="20.5" y2="20.5"/>
			<line style={strokeStyle} x1="43.5" y1="43.5" x2="43.5" y2="51"/>
			<line style={strokeStyle} x1="43.5" y1="43.5" x2="51" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="20.5" y2="13"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="13" y2="20.5"/>
			<line style={strokeStyle} x1="32" y1="55.8" x2="32" y2="48.3"/>
			<circle style={filledStrokeStyle} cx="32" cy="32" r="3.5"/>
		</symbol>
		<symbol id="13n" viewBox='0 0 64 64'>
			<line style={strokeStyle} x1="32" y1="48.3" x2="32" y2="8.2"/>
			<line style={strokeStyle} x1="32" y1="55.8" x2="32" y2="48.3"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="8.2" y2="32"/>
			<line style={strokeStyle} x1="55.8" y1="32" x2="48.3" y2="32"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="48.8" y2="15.2"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="37.3" y2="10.4"/>
			<line style={strokeStyle} x1="15.2" y1="48.8" x2="20.5" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="15.2" y2="15.2"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="26.7" y2="10.4"/>
			<line style={strokeStyle} x1="32" y1="48.3" x2="26.7" y2="53.6"/>
			<line style={strokeStyle} x1="32" y1="48.3" x2="37.3" y2="53.6"/>
			<line style={strokeStyle} x1="8.2" y1="32" x2="15.7" y2="32"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="53.6" y2="37.3"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="53.6" y2="26.7"/>
			<line style={strokeStyle} x1="15.7" y1="32" x2="10.4" y2="26.7"/>
			<line style={strokeStyle} x1="15.7" y1="32" x2="10.4" y2="37.3"/>
			<line style={strokeStyle} x1="48.8" y1="48.8" x2="43.5" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="43.6" x2="48.8" y2="15.2"/>
			<line style={strokeStyle} x1="15.2" y1="48.8" x2="20.5" y2="43.6"/>
			<line style={strokeStyle} x1="43.5" y1="43.6" x2="15.2" y2="15.2"/>
			<line style={strokeStyle} x1="48.8" y1="48.8" x2="43.5" y2="43.6"/>
			<line style={strokeStyle} x1="48.3" y1="32" x2="55.8" y2="32"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="51" y2="20.5"/>
			<line style={strokeStyle} x1="8.2" y1="32" x2="15.7" y2="32"/>
			<line style={strokeStyle} x1="32" y1="15.7" x2="32" y2="8.3"/>
			<line style={strokeStyle} x1="43.5" y1="20.5" x2="43.5" y2="13"/>
			<line style={strokeStyle} x1="20.5" y1="43.6" x2="13" y2="43.6"/>
			<line style={strokeStyle} x1="20.5" y1="43.6" x2="20.5" y2="51"/>
			<line style={strokeStyle} x1="15.2" y1="15.2" x2="20.5" y2="20.5"/>
			<line style={strokeStyle} x1="43.5" y1="43.5" x2="43.5" y2="51"/>
			<line style={strokeStyle} x1="43.5" y1="43.5" x2="51" y2="43.5"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="20.5" y2="13"/>
			<line style={strokeStyle} x1="20.5" y1="20.5" x2="13" y2="20.5"/>
			<line style={strokeStyle} x1="32" y1="55.8" x2="32" y2="48.3"/>
			<circle style={filledStrokeStyle} cx="32" cy="32" r="3.5"/>
		</symbol>
		<symbol id="50d" viewBox='0 0 64 64'>
			<circle style={strokeStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
			<line style={strokeStyle} x1="30.1" y1="47.2" x2="27.7" y2="47.2"/>
			<line style={strokeStyle} x1="23.6" y1="47.2" x2="21.2" y2="47.2"/>
			<line style={strokeStyle} x1="30.5" y1="56.9" x2="28.1" y2="56.9"/>
			<line style={strokeStyle} x1="24" y1="56.9" x2="21.6" y2="56.9"/>
			<line style={strokeStyle} x1="18" y1="56.9" x2="15.3" y2="56.9"/>
			<line style={strokeStyle} x1="42.3" y1="47.2" x2="39.9" y2="47.2"/>
			<line style={strokeStyle} x1="36.3" y1="47.2" x2="34" y2="47.2"/>
			<line style={strokeStyle} x1="49.2" y1="52" x2="46.7" y2="52"/>
			<line style={strokeStyle} x1="42.7" y1="52" x2="40.3" y2="52"/>
			<line style={strokeStyle} x1="36.7" y1="52" x2="34.4" y2="52"/>
			<line style={strokeStyle} x1="30.5" y1="61.7" x2="28.1" y2="61.7"/>
			<line style={strokeStyle} x1="24" y1="61.7" x2="21.6" y2="61.7"/>
			<line style={strokeStyle} x1="42.7" y1="61.7" x2="40.3" y2="61.7"/>
			<line style={strokeStyle} x1="36.7" y1="61.7" x2="34.4" y2="61.7"/>
		</symbol>
		<symbol id="50n" viewBox='0 0 64 64'>
			<circle style={filledStyle} cx="45.4" cy="20.7" r="12.5"/>
			<path style={filledStrokeStyle} d="M49.3,27.3H49c0-0.1,0-0.1,0-0.2c0.1-4.4-3.4-8-7.8-8.1c-1.4,0-2.8,0.4-4,1.2c-0.5-5.1-5-8.9-10.1-8.4
				c-4.8,0.4-8.5,4.5-8.4,9.3c0,1,0.2,2,0.5,2.9c-0.7-0.2-1.3-0.2-2-0.2c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3h32.2
				c4.2-0.2,7.5-3.7,7.3-7.9C56.5,30.6,53.3,27.5,49.3,27.3L49.3,27.3z"/>
			<line style={strokeStyle} x1="30.1" y1="47.2" x2="27.7" y2="47.2"/>
			<line style={strokeStyle} x1="23.6" y1="47.2" x2="21.2" y2="47.2"/>
			<line style={strokeStyle} x1="30.5" y1="56.9" x2="28.1" y2="56.9"/>
			<line style={strokeStyle} x1="24" y1="56.9" x2="21.6" y2="56.9"/>
			<line style={strokeStyle} x1="18" y1="56.9" x2="15.3" y2="56.9"/>
			<line style={strokeStyle} x1="42.3" y1="47.2" x2="39.9" y2="47.2"/>
			<line style={strokeStyle} x1="36.3" y1="47.2" x2="34" y2="47.2"/>
			<line style={strokeStyle} x1="49.2" y1="52" x2="46.7" y2="52"/>
			<line style={strokeStyle} x1="42.7" y1="52" x2="40.3" y2="52"/>
			<line style={strokeStyle} x1="36.7" y1="52" x2="34.4" y2="52"/>
			<line style={strokeStyle} x1="30.5" y1="61.7" x2="28.1" y2="61.7"/>
			<line style={strokeStyle} x1="24" y1="61.7" x2="21.6" y2="61.7"/>
			<line style={strokeStyle} x1="42.7" y1="61.7" x2="40.3" y2="61.7"/>
			<line style={strokeStyle} x1="36.7" y1="61.7" x2="34.4" y2="61.7"/>
		</symbol>
	</svg>
)

export default WeatherIconDefs
// <style type="text/css">
// 	.st0{fill:none;stroke:#000000;stroke-miterlimit:10;}
// 	.st1{fill:#FFFFFF;stroke:#000000;stroke-miterlimit:10;}
// 	.st2{fill:#FFFFFF;stroke:#000000;stroke-linecap:round;stroke-miterlimit:10;}
// </style>
