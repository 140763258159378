import {Link} from 'gatsby'
import React from 'react'
import ProjectPreview from './project-preview'

import styles from './related-projects.module.css'

const RelatedProjects = ({relatedProjects}) => {
	return (
		<div className={styles.root}>
			<ul className={styles.row}>
				{relatedProjects &&
					relatedProjects.map(node => (
						<li key={node.id}>
							<ProjectPreview {...node} />
						</li>
				))}
			</ul>
		</div>
	)
}

RelatedProjects.defaultProps = {
	relatedProjects: [],
}

export default RelatedProjects
