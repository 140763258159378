import {Link} from 'gatsby'
import React from 'react'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'
import ShapeOutline from './shapes/outlines'
import ShapeFill from './shapes/fills'

import styles from './project-preview.module.css'

const ProjectPreview = (props) => {
	return (
	    <Link className={styles.root} to={`/work/${props.slug.current}`}>
			<div className={styles.thumb}>
				{props.primaryImage && props.primaryImage.asset && (
					<img
						src={imageUrlFor(buildImageObj(props.primaryImage))
							.width(600)
							// .height(600)
							.url()}
						alt={props.primaryImage.alt}
						style={{clipPath: `url(#shape--${props.shape && props.shape.key})`, WebkitClipPath: `url(#shape--${props.shape && props.shape.key})`}}
					/>
				)}
				<ShapeOutline
					className={styles.thumb__outline}
					shape={props.shape && props.shape.key}
					strokeColor={props.category && props.category.catColor.color.hex}
				/>
				<ShapeFill
					className={styles.thumb__fill}
					shape={props.shape && props.shape.key}
					fillColor={props.category && props.category.catColor.color.hex}
				/>
			</div>
			<h2 className={styles.title}>{props.title}</h2>
	    </Link>
	)
}

export default ProjectPreview
