import React from 'react'

import styles from './masks.module.css'

const MaskDefs = () => (
	<svg xmlns="http://www.w3.org/2000/svg" className={styles.root}>
	    <defs>
			<clipPath id="shape--house" viewBox="0 0 432 432" clipPathUnits="objectBoundingBox" transform="scale(0.002314815)">
				<polygon points="29.6,153.4 29.6,402.7 403.6,402.7 403.6,153.4 216.6,28.7"/>
			</clipPath>
			<clipPath id="shape--clover" viewBox="0 0 432 432" clipPathUnits="objectBoundingBox" transform="scale(0.002314815)">
				<path d="M403.6,144.5c0-63.9-51.8-115.7-115.7-115.7c-26.9,0-51.6,9.2-71.3,24.6c-19.6-15.4-44.4-24.6-71.3-24.6
					c-63.9,0-115.7,51.8-115.7,115.7c0,26.9,9.2,51.6,24.6,71.3c-15.4,19.6-24.6,44.4-24.6,71.3c0,63.9,51.8,115.7,115.7,115.7
					c26.9,0,51.6-9.2,71.3-24.6c19.6,15.4,44.4,24.6,71.3,24.6c63.9,0,115.7-51.8,115.7-115.7c0-26.9-9.2-51.6-24.6-71.3
					C394.4,196.1,403.6,171.3,403.6,144.5z"/>
			</clipPath>

			<clipPath id="shape--ellipse" viewBox="0 0 432 432" clipPathUnits="objectBoundingBox" transform="scale(0.002314815)">
				<ellipse cx="216.3" cy="215.7" rx="186.1" ry="124.9"/>
			</clipPath>

			<clipPath id="shape--rect" viewBox="0 0 432 432" clipPathUnits="objectBoundingBox" transform="scale(0.002314815)">
				<rect x="30.5" y="90.8" width="372.3" height="249.9"/>
			</clipPath>

			<clipPath id="shape--arch" viewBox="0 0 432 432" clipPathUnits="objectBoundingBox" transform="scale(0.002314815)">
				<path d="M403.6,200.4C402.5,98,319.2,15.3,216.6,15.3C114,15.3,30.7,98,29.6,200.4h0v2.1c0,0,0,0,0,0l0,0v200.3l374,0L403.6,200.4
					L403.6,200.4z"/>
			</clipPath>
		</defs>
	</svg>
)

export default MaskDefs
