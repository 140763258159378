import React from 'react'

const strokeStyle = {
	vectorEffect: 'non-scaling-stroke',
	stroke: 'black',
	strokeWidth: 1
}

const PlusIcon = ({width, height}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		xmlns="http://www.w3.org/2000/svg"
	>
	    <g>
			<line
				style={strokeStyle}
				x1={width / 2}
				y1="0"
				x2={width / 2}
				y2={height}
			/>
			<line
				style={strokeStyle}
				x1="0"
				y1={height / 2}
				x2={width}
				y2={height / 2}
			/>
	    </g>
	</svg>
)

export default PlusIcon
